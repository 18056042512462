<template>
  <div class="contractsponsor">
    <div class="middle">
      <div class="fs-18 c-999 mb-20">点击按钮发起签署</div>
      <oabutton @buttonclick='toLink' title="发起电子合同" CSStype=9 width=298
        height=52></oabutton>
    </div>
  </div>
</template>

<script setup>
import { router_push_name } from '@/utils/server/router'
import { handleMessage } from "@/utils/server/confirm";
import {getCompanyRealname,getUserOrCompany} from '@/utils/base/realname.js'
const toLink = () => {
  if(getUserOrCompany()==1){
    router_push_name('operation_initiate_contract')
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color/value.scss";
.contractsponsor {
  width: 361px;
  background: $active-alpha;
  border: 1px dashed $active-theme;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>